import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { RootState, AppDispatch } from "../Redux/store";
import { resetUser } from "../Redux/reducer/userSlice";
import "./Account.css";
import { url_changePassword } from "../lib/url";

const Account: React.FC = () => {
  const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const toast = useRef<Toast>(null);

  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user);
  const token = useSelector((state: RootState) => state.user.token);

  const handleLogout = () => {
    dispatch(resetUser());
    toast.current?.show({
      severity: "success",
      summary: "Logout effettuato",
      detail: "Sei stato disconnesso con successo.",
      life: 3000,
    });
  };

const handleChangePasswordSubmit = async () => {
  if (!user.patientInfo || !user.patientInfo.email) {
    toast.current?.show({
      severity: "error",
      summary: "Errore",
      detail: "Informazioni dell'utente mancanti. Riprova dopo il login.",
      life: 3000,
    });
    return;
  }

  if (!currentPassword || !newPassword || !confirmPassword) {
    toast.current?.show({
      severity: "error",
      summary: "Errore",
      detail: "Compila tutti i campi richiesti.",
      life: 3000,
    });
    return;
  }

  if (newPassword !== confirmPassword) {
    toast.current?.show({
      severity: "error",
      summary: "Errore",
      detail: "La nuova password e la conferma non coincidono.",
      life: 3000,
    });
    return;
  }

  try {
    const response = await fetch(url_changePassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: user.patientInfo.email,
        oldPassword: currentPassword,
        newPassword,
      }),
    });

    if (!response.ok) {
      const responseData = await response.json();

      if (responseData.errori && responseData.errori.includes("Incorrect password.")) {
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: "La password attuale inserita non è corretta.",
          life: 3000,
        });
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: responseData.errori?.join(", ") || "Errore durante il cambio della password.",
          life: 3000,
        });
      }
      return;
    }

    toast.current?.show({
      severity: "success",
      summary: "Successo",
      detail: "Password aggiornata con successo.",
      life: 3000,
    });
    setChangePasswordVisible(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  } catch (error) {
    toast.current?.show({
      severity: "error",
      summary: "Errore",
      detail: "Errore durante il cambio della password. Riprova più tardi.",
      life: 3000,
    });
  }
};

  return (
    <div className="account-div">
      <Toast ref={toast} />
      <h2>
        Benvenuto, {user.patientInfo?.firstName} {user.patientInfo?.lastName}
      </h2>
      <div className="user-info">
        <p>
          <strong>Codice Fiscale:</strong> {user.codiceFiscale}
        </p>
        <p>
          <strong>Email:</strong> {user.patientInfo?.email || "Non disponibile"}
        </p>
        <p>
			<strong>Data di Nascita:</strong>{" "}
			{user.patientInfo?.birthDate
			  ? new Date(user.patientInfo.birthDate).toLocaleDateString("it-IT", {
				  day: "2-digit",
				  month: "2-digit",
				  year: "numeric",
				})
			  : "Non disponibile"}
        </p>
      </div>
      <div className="account-actions">
        <Button
          label="Cambia Password"
          icon="pi pi-key"
          onClick={() => setChangePasswordVisible(true)}
          className="p-button-secondary"
        />
        <Button
          label="Logout"
          icon="pi pi-sign-out"
          onClick={handleLogout}
          className="p-button-danger"
        />
      </div>

      <Dialog
        header="Cambia Password"
        visible={changePasswordVisible}
        style={{ width: "50vw" }}
        onHide={() => setChangePasswordVisible(false)}
        footer={
          <div>
            <Button
              label="Annulla"
              icon="pi pi-times"
              onClick={() => setChangePasswordVisible(false)}
              className="p-button-text"
            />
            <Button
              label="Conferma"
              icon="pi pi-check"
              onClick={handleChangePasswordSubmit}
              autoFocus
            />
          </div>
        }
      >
        <div className="password-change-form">
          <div className="p-field">
            <label htmlFor="currentPassword">Password Attuale</label>
            <Password
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              feedback={false}
            />
          </div>
          <div className="p-field">
            <label htmlFor="newPassword">Nuova Password</label>
            <Password
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              feedback={false}
            />
          </div>
          <div className="p-field">
            <label htmlFor="confirmPassword">Conferma Password</label>
            <Password
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              feedback={false}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Account;
