import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { RootState, AppDispatch } from "../Redux/store";
import { setCodiceFiscale, setLoggedIn, setRememberMe, resetUser, setPatientInfo } from "../Redux/reducer/userSlice";
import "./Login.css";
import { url_login, url_passwordForgot, url_changePassword, url_resendEmail, url_patient_info } from "../lib/url";
import { Dialog } from "primereact/dialog";

interface LoginBody {
  username: string;
  password: string;
  rememberMe: boolean;
}

interface PasswordForgotBody {
  email: string;
}

interface ChangePasswordBody {
  email: string;
  oldPassword: string;
  newPassword: string;
}

const Login: React.FC<{ setActiveIndex: (index: number) => void }> = ({ setActiveIndex }) => {
  const [password, setPassword] = useState<string>("");
  const [codiceFiscale, setCodiceFiscaleInput] = useState<string>("");
  const [rememberMe, setRememberMeInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [resendEmailVisible, setResendEmailVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState<boolean>(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const toast = useRef<Toast>(null);
  const token = useSelector((state: RootState) => state.user.token);
  const user = useSelector((state: RootState) => state.user);
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  const handleResendEmail = async () => {
    try {
      const response = await fetch(url_resendEmail, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast.current?.show({
          severity: "success",
          summary: "Email di conferma inviata",
          detail: "Si prega di controllare l'email per confermare il proprio account.",
          life: 3000,
        });
      } else {
        const responseData = await response.json();
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: responseData.error || "Si è verificato un errore durante l'invio dell'email di conferma.",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Errore durante l'invio dell'email di conferma:", error);
      toast.current?.show({
        severity: "error",
        summary: "Errore",
        detail: "Si è verificato un errore durante l'invio dell'email di conferma.",
        life: 3000,
      });
    } finally {
      setResendEmailVisible(false);
      setEmail("");
    }
  };

  const handleLoginSubmit = () => {
    if (password && codiceFiscale) {
      const loginBody: LoginBody = {
        username: codiceFiscale,
        password: password,
        rememberMe: rememberMe,
      };
      postLogin(loginBody);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Login Failed",
        detail: "Compila tutti i campi.",
        life: 3000,
      });
    }
  };

const postLogin = async (bodyLog: LoginBody) => {
  try {

    if (!token) {
      throw new Error("Token di autorizzazione mancante.");
    }

    // Effettua la chiamata al servizio login
    const response = await fetch(url_login, {
      method: "POST",
      body: JSON.stringify(bodyLog),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include il token qui
      },
    });

    // Gestione della risposta
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      const errorMessage = errorData?.messaggio || "Errore durante il login.";
      throw new Error(errorMessage);
    }

    const responseData = await response.json();

    // Salva il nuovo token ricevuto (se esiste) nel localStorage
    const newToken = responseData.accessToken;
    if (newToken) {
      localStorage.setItem("accessToken", newToken);
    }

    // Aggiorna Redux con i dettagli dell'utente
    dispatch(setCodiceFiscale(bodyLog.username));
    dispatch(setLoggedIn(true));
    dispatch(setRememberMe(bodyLog.rememberMe));

    // Recupera le informazioni del paziente
    await fetchPatientInfo(bodyLog.username, newToken || token);

    toast.current?.show({
      severity: "success",
      summary: "Login riuscito",
      detail: "Login effettuato con successo. Puoi accedere alle funzionalità.",
      life: 6000,
    });
    setActiveIndex(0); // Naviga alla pagina principale
  } catch (error) {
    console.error("Errore durante il login:", error);

    toast.current?.show({
      severity: "error",
      summary: "Errore",
      detail: error instanceof Error ? error.message : "Ops! Qualcosa è andato storto.",
      life: 6000,
    });
  }
};


  const handleForgotPasswordSubmit = () => {
    if (email) {
      const forgotPasswordBody: PasswordForgotBody = {
        email: email,
      };
      postForgotPassword(forgotPasswordBody);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Password Reset Failed",
        detail: "Inserisci un'email valida.",
        life: 3000,
      });
    }
  };

  const postForgotPassword = async (body: PasswordForgotBody) => {
    try {
      const response = await fetch(url_passwordForgot, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.current?.show({
          severity: "success",
          summary: "Successo",
          detail: responseData.messaggio,
          life: 6000,
        });
        setForgotPasswordVisible(false);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: responseData.messaggio,
          life: 6000,
        });
      }
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Errore",
        detail: "Errore durante il reset della password. Riprova più tardi.",
        life: 3000,
      });
    }
  };

  const handleChangePasswordSubmit = () => {
    if (oldPassword && newPassword && email) {
      const changePasswordBody: ChangePasswordBody = {
        email: email,
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      postChangePassword(changePasswordBody);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Errore",
        detail: "Compila tutti i campi richiesti.",
        life: 3000,
      });
    }
  };


const fetchPatientInfo = async (codiceFiscale: string, token: string) => {
  try {
    const response = await fetch(`${url_patient_info}?codiceFiscale=${codiceFiscale}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Errore durante il recupero delle informazioni del paziente.");
    }

    const patientInfo = await response.json();
    dispatch(setPatientInfo(patientInfo)); // Aggiorna Redux con le informazioni del paziente
  } catch (error) {
    toast.current?.show({
      severity: "error",
      summary: "Errore",
      detail: error instanceof Error ? error.message : "Errore sconosciuto.",
      life: 3000,
    });
  }
};


  const postChangePassword = async (body: ChangePasswordBody) => {
    try {
      const response = await fetch(url_changePassword, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.current?.show({
          severity: "success",
          summary: "Successo",
          detail: "Password aggiornata con successo.",
          life: 6000,
        });
        setChangePasswordVisible(false);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: responseData.messaggio,
          life: 6000,
        });
      }
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Errore",
        detail: "Errore durante il cambio della password. Riprova più tardi.",
        life: 3000,
      });
    }
  };

  const handleLogout = () => {
    dispatch(resetUser());
    toast.current?.show({
      severity: "success",
      summary: "Logout Eseguito",
      detail: "Sei stato disconnesso con successo.",
      life: 3000,
    });
  };

  const getWelcomeMessage = () => {
    const genderMap: { [key: string]: string } = {
      "1": "Benvenuto",
      "0": "Benvenuta",
    };
    const welcomeMessage = genderMap[user.patientInfo?.sexId?.trim() || ""] || "Benvenuto";
    return (
      <span className="welcome-message">
        {welcomeMessage} {user.patientInfo?.firstName || ""} {user.patientInfo?.lastName || ""}
      </span>
    );
  };

  return (
    <div className="login-div">
      <Toast ref={toast} />
      {isLoggedIn ? (
        <div className="welcome-div">
          <h2>{getWelcomeMessage()}</h2>
          <div className="button-container">
            <Button className="logout-btn" label="Logout" icon="pi pi-sign-out" onClick={handleLogout} />
            <Button
              className="change-password-btn"
              label="Cambia Password"
              icon="pi pi-key"
              onClick={() => setChangePasswordVisible(true)}
            />
          </div>
        </div>
      ) : (
        <div className="login-form">
          <h2>Login</h2>
          <div className="login-input">
            <FloatLabel>
              <InputText
                id="codiceFiscale"
                value={codiceFiscale}
                onChange={e => setCodiceFiscaleInput(e.target.value)}
                className="input-field"
              />
              <label>Codice Fiscale</label>
            </FloatLabel>
          </div>
          <div className="login-input">
            <FloatLabel>
              <Password
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="input-field"
                toggleMask
              />
              <label>Password</label>
            </FloatLabel>
          </div>
          <div className="login-input" style={{ display: "flex", alignItems: "center" }}>
            <InputSwitch
              style={{ marginRight: "2rem" }}
              checked={rememberMe}
              onChange={e => setRememberMeInput(e.value)}
            />
            <label>Resta connesso</label>
          </div>
          <div className="card flex flex-wrap justify-content-center gap-3">
            <Button className="submit-btn" label="Login" icon="pi pi-check" onClick={handleLoginSubmit} />
          </div>
          <div className="register-link">
            <p>
              Non hai un account? {" "}
              <a style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setActiveIndex(3)}>
                Registrati qui
              </a>
            </p>
            <p>
              Hai dimenticato la password? {" "}
              <a
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => setForgotPasswordVisible(true)}
              >
                Clicca qui
              </a>
            </p>
            <p>
              Rinvia email di conferma?{" "}
              <a style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setResendEmailVisible(true)}>
                Clicca qui
              </a>
            </p>
          </div>
          <Dialog
            header="Rinvia email di conferma"
            visible={resendEmailVisible}
            style={{ width: "50vw", textAlign: "center" }}
            footer={
              <div>
                <Button
                  label="Annulla"
                  style={{ borderRadius: "10px" }}
                  icon="pi pi-times"
                  onClick={() => setResendEmailVisible(false)}
                />
                <Button label="Invia" style={{ borderRadius: "10px" }} icon="pi pi-check" onClick={handleResendEmail} />
              </div>
            }
            onHide={() => setResendEmailVisible(false)}
          >
            <div className="p-field">
              <label htmlFor="email" style={{ marginRight: "1rem" }}>
                Email
              </label>
              <InputText id="email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
          </Dialog>
        </div>
      )}
      {forgotPasswordVisible && (
        <div className="forgot-password-dialog">
          <h2>Password Dimenticata</h2>
          <div className="forgot-password-input">
            <FloatLabel>
              <InputText id="email" value={email} onChange={e => setEmail(e.target.value)} className="input-field" />
              <label>Email</label>
            </FloatLabel>
          </div>
          <div className="card flex flex-wrap justify-content-center gap-3">
            <Button className="submit-btn" label="Invia" icon="pi pi-envelope" onClick={handleForgotPasswordSubmit} />
            <Button
              className="cancel-btn"
              style={{ borderRadius: "10px" }}
              label="Annulla"
              icon="pi pi-times"
              onClick={() => setForgotPasswordVisible(false)}
            />
          </div>
        </div>
      )}
      {changePasswordVisible && (
        <div className="change-password-dialog">
          <h2>Cambia Password</h2>
          <div className="change-password-input">
            <FloatLabel>
              <InputText id="email" value={email} onChange={e => setEmail(e.target.value)} className="input-field" />
              <label>Email</label>
            </FloatLabel>
          </div>
          <div className="change-password-input">
            <FloatLabel>
              <Password
                id="oldPassword"
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
                className="input-field"
                toggleMask
              />
              <label>Vecchia Password</label>
            </FloatLabel>
          </div>
          <div className="change-password-input">
            <FloatLabel>
              <Password
                id="newPassword"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                className="input-field"
                toggleMask
              />
              <label>Nuova Password</label>
            </FloatLabel>
          </div>
          <div className="card flex flex-wrap justify-content-center gap-3">
            <Button
              className="submit-btn"
              label="Cambia Password"
              icon="pi pi-check"
              onClick={handleChangePasswordSubmit}
            />
            <Button
              className="cancel-btn"
              style={{ borderRadius: "10px" }}
              label="Annulla"
              icon="pi pi-times"
              onClick={() => setChangePasswordVisible(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
