import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";

// Definizione dello stato utente
export interface UserState {
  isLoggedIn: boolean;
  token: string;
  codiceFiscale: string;
  patientInfo: {
    firstName: string;
    lastName: string;
    email: string;
    sexId?: string;
    birthDate?: string;
    patientId?: string;
  } | null;
  rememberMe: boolean;
  appointment: any | null; // Aggiungi questa riga
}

const initialState: UserState = {
  isLoggedIn: false,
  token: "",
  codiceFiscale: "",
  patientInfo: null, // Include informazioni aggiuntive come email
  rememberMe: false,
  appointment: null,
};

// Action per impostare patientInfo
export const setPatientInfo = createAction<any>("user/setPatientInfo");
// Action per impostare un appuntamento
export const setAppointment = createAction<any>("user/setAppointment");

// Reducer aggiornato
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    setCodiceFiscale(state, action: PayloadAction<string>) {
      state.codiceFiscale = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setRememberMe(state, action: PayloadAction<boolean>) {
      state.rememberMe = action.payload;
    },
    resetUser(state) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(setPatientInfo, (state, action) => {
      state.patientInfo = action.payload;
    });
    builder.addCase(setAppointment, (state, action) => {
      state.appointment = action.payload;
    });
  },
});

export const { setLoggedIn, setCodiceFiscale, setToken, setRememberMe, resetUser } = userSlice.actions;
export default userSlice.reducer;
